export default {
  data() {
    return {
      deviceWidth: window.innerWidth,
    };
  },
  created() {
    window.addEventListener("resize", this.updateDeviceWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.updateDeviceWidth);
  },
  methods: {
    updateDeviceWidth() {
      this.deviceWidth = window.innerWidth;
    },
  },
};
