<template>
  <div>
    <modal
      id="modalUpsell"
      ref="modalUpsell"
      size="xl"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      modal-class="tw-p-4 tw-pb-14 lg:tw-p-0 tw-pt-10 lg:tw-pt-0"
      content-class="tw-bg-light-gray tw-rounded-2xl tw-relative tw-overflow-hidden"
      :dialog-class="`tw-mx-auto tw-max-w-full md:tw-max-w-[800px] lg:tw-max-w-[1100px] ${upsellFrom === 'Onboarding Upsell' ? 'dialog-modal-upsell tw-my-0 lg:tw-my-7' : 'tw-my-0'}`"
    >
      <div
        v-if="upsellFrom === 'Onboarding Upsell'"
        class="tw-text-center tw-mb-0 lg:tw-mb-9"
      >
        <h1 class="tw-mb-1 lg:tw-leading-[3rem] tw-text-xl md:tw-text-3xl lg:tw-text-4xl">
          These exclusive offerings from Winona are only available at a <br class="tw-hidden lg:tw-block">
          discounted rate for new patients <span class="tw-text-lavender-purple tw-font-bold">RIGHT NOW ONLY</span>!
        </h1>
      </div>
      <div
        v-if="isDesktop"
        class="tw-hidden lg:tw-block tw-space-y-6"
      >
        <SelectProductUpsell
          v-for="(product, i) in upsellProductList"
          :key="product.id"
          :product="product"
          :idx="i"
          :upsell-from="upsellFrom"
          @upsell-processing="onAddUpsellProcessing"
          @upsell-change="onMultipleUpsellChange"
          @upsell-remove="onMultipleUpsellRemove"
          @error="onUpsellError"
        />
      </div>
      <div
        v-else
        class="tw-flex tw-flex-row tw-flex-wrap lg:tw-hidden tw-relative tw-overflow-hidden tw-rounded-2xl"
      >
        <div
          v-for="(product, i) in upsellProductList"
          v-show="page === i + 1"
          :key="product.id"
          :class="{ 'tw-mt-4 lg:tw-mt-10': product.upsellcode === 'CREAM' && upsellFrom === 'Onboarding Upsell' }"
        >
          <SelectProductUpsell
            :product="product"
            :idx="i"
            :upsell-from="upsellFrom"
            @upsell-processing="onAddUpsellProcessing"
            @upsell-change="onMultipleUpsellChange"
            @upsell-remove="onMultipleUpsellRemove"
            @error="onUpsellError"
          />
        </div>
      </div>
  
      <div
        class="tw-flex tw-justify-center lg:tw-justify-end tw-items-center tw-gap-4 lg:tw-gap-0 tw-text-right tw-mt-6"
        :class="{ 'tw-flex-col-reverse lg:tw-flex-row': !(page > 1) }"
      >
        <b-button
          v-if="page > 1"
          variant="link"
          size="sm"
          class="tw-flex tw-px-0"
          @click="page -= 1"
        >
          <span class="tw-rotate-180 next-arrow-purple"></span>
        </b-button>

        <b-button
          v-if="upsellFrom !== 'Onboarding Upsell' && !processing"
          class="tw-font-light tw-mr-0 lg:tw-mr-4 tw-w-full sm:tw-w-auto"
          variant="outline-info"
          size="lg"
          pill
          :disabled="processing"
          @click="closeUpsellModal"
        >
          No thanks, Maybe later
        </b-button>

        <template v-if="!isDesktop">
          <b-button
            v-if="
              isEveryUpsellNull && (page > 1 || upsellProductList.length === 1)
            "
            class="tw-p-0 tw-underline tw-font-light"
            variant="link"
            size="lg"
            :disabled="processing"
            @click="closeUpsellModal"
          >
            <b-spinner
              v-if="isPurchasing || processing"
              small
            />
            <span v-else>
              No thanks, Maybe later
            </span>
          </b-button>
          <b-button
            v-else
            class="tw-font-light tw-w-full sm:tw-w-auto"
            variant="info"
            size="lg"
            pill
            :disabled="processing"
            @click="onMoveToNextPage"
          >
            <b-spinner
              v-if="isPurchasing || processing"
              small
            />
            <span v-else>
              {{
                upsellProductList.length > 1 && page === 1 ? "Continue" : purchaseButtonText
              }}
            </span>
          </b-button>
        </template>
        <template v-else>
          <b-button
            v-if="isEveryUpsellNull"
            class="tw-font-light"
            variant="info"
            size="lg"
            pill
            :disabled="processing"
            @click="closeUpsellModal"
          >
            <b-spinner
              v-if="isPurchasing || processing"
              small
            />
            <span v-else>
              No thanks, Maybe later
            </span>
          </b-button>
          <b-button
            v-else
            class="tw-font-light"
            variant="info"
            size="lg"
            pill
            :disabled="processing"
            @click="onAcceptUpsell"
          >
            <b-spinner
              v-if="isPurchasing || processing"
              small
            />
            <span v-else> {{ purchaseButtonText }} </span>
          </b-button>
        </template>
      </div>
    </modal>
    <slot name="modal"></slot>
  </div>
</template>

<script>
import deviceWidthMixin from '@/mixins/deviceWidthMixin';
import SelectProductUpsell from '@/components/onboarding/checkout/SelectProductUpsell';
import { OnboardingService } from '@/services/onboarding.service';

export default {
  name: 'OneClickUpsell',
  components: {
    SelectProductUpsell,
  },
  mixins: [deviceWidthMixin],
  props: {
    isUpsellModalVisible: {
      type: Boolean,
      default: false,
    },
    upsellFrom: {
      type: String,
      default: '',
      required: true,
    },
    couponid: {
      type: String,
      default: '',
    },
    upsellCode: {
      type: String,
      default: '',
    },
    allUpsell: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      selectedUpsells: [],
      couponData: {},
      page: 1,
      processing: false,
      isPurchasing: false,      
    };
  },
  computed: {
    upsellProductList() {
      const upsellList = this.allUpsell?.length > 0 ? this.allUpsell : this.$store.state.upsell.availableDashboardUpsell;
      return this.upsellCode
        ? upsellList.filter(upsell => upsell.upsellcode === this.upsellCode)
        : upsellList;
    },
    isEveryUpsellNull() {
      return this.selectedUpsells.every(value => value === null);
    },
    isDesktop() {
      return this.deviceWidth > 1024;
    },
    purchaseButtonText() {
      return this.upsellFrom === 'Onboarding Upsell' ? 'Continue' : 'Purchase';
    },
    hasEmptyUpsellId() {
      return this.selectedUpsells.some(upsell => upsell && !upsell.upsellId);
    }
  },
  watch: {
    async isUpsellModalVisible(val) {
      if (val) {
        this.$bvModal.show('modalUpsell');
      } else {
        this.$bvModal.hide('modalUpsell');
      }
    },
  },
  async created() {
    if (this.upsellFrom === 'Onboarding Upsell') {
      this.$bvModal.show('modalUpsell');
    } else {
      await this.$store.dispatch('upsell/fetchAvailableDashboardUpsell');
    }
  },
  async mounted() {
    if (this.couponid && this.couponid.length > 0) {
      const onboarding_blossom_promocodes = [
        'BLACKFRIDAY2022',
        'CYBERMONDAY2022',
      ];
      if (onboarding_blossom_promocodes.includes(this.couponid)) {
        this.$cookies.set('promocode', 'BFBLOSSOM10');
        const { data } = await OnboardingService.checkCoupon('BFBLOSSOM10');
        this.couponData = data;
      } else {
        // Get coupon
        const { data } = await OnboardingService.checkCoupon(this.couponid);
        this.couponData = data;
      }
    }
  },
  methods: {
    closeUpsellModal() {
      this.selectedUpsells = []
      this.couponData = {}
      this.processing = false
      this.isPurchasing = false
      this.page = 1;
      this.$emit('update:isUpsellModalVisible', false);
    },
    onMoveToNextPage() {
      if (this.page !== this.upsellProductList.length) {
        this.page += 1;
        return;
      }
      this.onAcceptUpsell();
    },
    async onAcceptUpsell() {
      if (this.hasEmptyUpsellId && this.upsellFrom !== 'Onboarding Upsell') {
        this.closeUpsellModal();
        this.$bvModal.show('upsellSuccess')
        return;
      }

      if (!this.isEveryUpsellNull) {
        const upsellPayload = this.selectedUpsells
          .filter(upsell => upsell)
          .map(upsell => {
            return {
              upsellid: upsell.upsellId,
              productid: upsell.productId,
              pricingid: upsell.pricingId,
              triggeredfrom: this.upsellFrom,
              ...(upsell.upsellcode === 'CREAM' && this.upsellFrom === 'Onboarding Upsell' && {
                coupon: 'ESTRIOL51'
              })
            };
          });

        this.processing = true;
        this.isPurchasing = true;
        await this.$store.dispatch(
          'upsell/acceptMultipleUpsell',
          upsellPayload
        );
        this.processing = false;
        this.isPurchasing = false;
        this.closeUpsellModal();
        this.$emit('success');
        if ($cookies.get('promocode')) {
          $cookies.remove('promocode');
        }
      }
    },
    onMultipleUpsellChange(upsellData) {
      this.selectedUpsells.splice(upsellData.idx, 1, upsellData);
    },
    onMultipleUpsellRemove(idx) {
      this.selectedUpsells.splice(idx, 1, null);
    },
    onAddUpsellProcessing(processing) {
      this.processing = processing;
    },
    onUpsellError() {
      this.closeUpsellModal()
      this.$bvModal.hide('modalUpsell');
    },
  },
};
</script>
