<template>
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4948 21.4896C16.2362 21.4896 20.9896 16.7259 20.9896 10.9948C20.9896 5.25354 16.2259 0.5 10.4845 0.5C4.75354 0.5 0 5.25354 0 10.9948C0 16.7259 4.76383 21.4896 10.4948 21.4896ZM10.4948 19.7406C5.63841 19.7406 1.75943 15.8513 1.75943 10.9948C1.75943 6.13841 5.62812 2.24914 10.4845 2.24914C15.341 2.24914 19.2406 6.13841 19.2406 10.9948C19.2406 15.8513 15.3513 19.7406 10.4948 19.7406Z"
      fill="currentColor"
    />
    <path
      d="M9.36397 15.8819C9.70349 15.8819 9.99157 15.7172 10.1974 15.3983L14.8995 8.00046C15.0127 7.79467 15.1464 7.56832 15.1464 7.34196C15.1464 6.87895 14.7348 6.58057 14.3027 6.58057C14.0455 6.58057 13.7882 6.74519 13.5928 7.04357L9.32279 13.8961L7.29583 11.2724C7.0489 10.9431 6.82254 10.8608 6.53445 10.8608C6.09202 10.8608 5.74219 11.2209 5.74219 11.6737C5.74219 11.9 5.83479 12.1161 5.97883 12.3116L8.48937 15.3983C8.7466 15.7379 9.02436 15.8819 9.36397 15.8819Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
