<template>
  <div
    class="checkbox-container tw-flex tw-items-center tw-cursor-pointer tw-select-none"
    :style="{ background: color }"
    @click="isChecked = !isChecked"
  >
    <div
      class="checkbox-box tw-size-6 tw-flex tw-justify-center tw-items-center tw-mr-2 !tw-bg-white"
      :style="{ color, border: `1px solid ${color}` }"
    >
      <IconCheck v-if="isChecked" />
      <div
        v-else
        class="tw-size-6"
      ></div>
    </div>
    <span>
      <slot></slot>
    </span>
  </div>
</template>

<script>
import IconCheck from '@/components/icon/upsell/IconCheck.vue';

export default {
  name: 'BaseCheckbox',
  components: {
    IconCheck,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#000',
    },
  },
  computed: {
    isChecked: {
      get() {
        return this.value
      },
      set(checked) {
        this.$emit('input', checked);
      }
    }
  }
};
</script>
